.legend {
  display: flex;
  /* flex-direction: column; */

  justify-content: center;
  gap: 1rem;
}
.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.legend-color {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  border-radius: 8px;
}
.legend-label {
  font-size: 12px;
}
.legend-score {
  /* font-weight: bold; */
  margin-left: 5px;
}
