@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }

  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  min-height: 100vh;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bottom-img-style {
  background-image: url("/public/bottom.svg");
  width: 100%;
  height: 450px;
  /* position: absolute; */
  bottom: 0;
  left: 0;
  background-size: cover;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.wrapper {
  display: flex;
  width: 100%;
}

.content {
  flex-grow: 1;
  /* Allow the content to grow to fill remaining space */
  overflow-y: auto;
  /* Enable vertical scrolling for the content */
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  /* Hide scrollbar for Internet Explorer and Edge */
}

.content::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit (Chrome, Safari, etc.) */
}

.sidebar {
  position: sticky;
  /* Set the position to fixed */
  top: 0;
  /* Stick to the top of the viewport */
  left: 0;
  /* Stick to the left of the viewport */

  height: 100%;
  /* Take up the full height of the viewport */
  overflow-y: auto;
  /* Enable vertical scrolling for the sidebar */
  /* border-right: 1px solid #ccc; */
  /* Optional: Add a border to separate from content */
}

/* Dark Mode */
/* login Css*/


main [data-variation="modal"] {
  background-color: #fcfcfc !important;
  padding: 50px 0px;
}



.css-yn7tg6 [data-amplify-authenticator] [data-amplify-router],
.css-yhw27t [data-amplify-authenticator] [data-amplify-router],
main [data-amplify-authenticator] [data-amplify-router] {
  position: relative;
  background-color: #f2f0f0;
  border: 0;
  border-radius: 20px;
  box-shadow: 0px 12px 12px -6px rgb(0 0 0 / 20%);
  overflow: hidden;
}

.css-yn7tg6 [data-amplify-authenticator] [data-amplify-form] .amplify-label,
main [data-amplify-authenticator] [data-amplify-form] .amplify-label,
main [data-amplify-authenticator] [data-amplify-form] .amplify-radio,
main
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-radio
  .amplify-text,
main [data-amplify-authenticator] [data-amplify-form] .amplify-heading {
  color: #1e1e35;
}

.css-yn7tg6 [data-amplify-authenticator] [data-amplify-form] .amplify-input,
main [data-amplify-authenticator] [data-amplify-form] .amplify-input {
  border-color: #1e1e35;
  padding: 15px 20px;
  border-radius: 10px;
  color: #1e1e35;
  background-color: transparent !important;
}

main
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-radio
  .amplify-radio__input
  + .amplify-radio__button {
  width: 20px;
  height: 20px;
  padding: 3px;
}

main
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-radio
  .amplify-radio__input:checked
  + .amplify-radio__button {
  color: #ffffff;
  border-color: #ffffff;
}

.css-yn7tg6
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-input:focus,
main [data-amplify-authenticator] [data-amplify-form] .amplify-input:focus {
  border-color: #1e1e35;
  color: #1e1e35;
}

main .amplify-button:focus {
  background-color: transparent;
  box-shadow: unset;
}

.css-yn7tg6
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-field-group--horizontal:has(input:focus)
  .amplify-field-group__outer-end
  .amplify-field-group__control,
main
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-field-group--horizontal:has(input:focus)
  .amplify-field-group__outer-end
  .amplify-field-group__control {
  border-color: #8282b5;
}

.css-yn7tg6 .amplify-input::placeholder,
main .amplify-input::placeholder {
  color: #545474;
}

.css-yn7tg6
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-field-group__outer-end
  .amplify-field-group__control,
main
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-field-group__outer-end
  .amplify-field-group__control {
  border-color: #1e1e35;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
}

.css-yn7tg6 .amplify-icon > svg path,
main .amplify-icon > svg path {
  fill: #1e1e35;
}

main [data-amplify-authenticator] [data-amplify-form] fieldset.amplify-flex {
  gap: 30px;
  margin-bottom: 30px;
}

main [data-amplify-authenticator] [data-amplify-form] fieldset.amplify-flex h3.amplify-heading {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
}

main
  [data-amplify-authenticator]
  [data-amplify-form]
  button.amplify-button[type="submit"] {
  background-color: #4cceac;
  color: #ffffff;
  font-weight: 600;
  padding-block: 15px;
  border-radius: 10px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

main [data-amplify-authenticator] [data-amplify-form] {
  padding-bottom: 10px;
}

.css-yn7tg6 [data-amplify-authenticator] [data-amplify-footer] button,
.css-yn7tg6
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-button--link,
main [data-amplify-authenticator] [data-amplify-footer] button,
main [data-amplify-authenticator] [data-amplify-form] .amplify-button--link {
  color: #1e1e35;
  font-size: 14px;
}

main [data-amplify-authenticator] [data-amplify-footer] {
  padding-bottom: 40px;
}

.css-yn7tg6 .amplify-field-group__outer-end .amplify-field-group__control:hover,
.css-yn7tg6 [data-amplify-authenticator] [data-amplify-footer] button:hover,
.css-yn7tg6
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-button--link:hover,
main .amplify-field-group__outer-end .amplify-field-group__control:hover,
main [data-amplify-authenticator] [data-amplify-footer] button:hover,
main
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-button--link:hover {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  color: #1e1e35 !important;
  -webkit-text-fill-color: #1e1e35;
}

.css-yn7tg6
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-heading--3 {
  color: #fff;
  font-weight: 600;
}

.css-yn7tg6 span.amplify-text.amplify-radio__label {
  color: #1e1e35;
}

.css-yn7tg6 .css-nchosy .css-s5hy0i-MuiGrid-root .sticky,
.css-yn7tg6 .cashflow-right {
  background-color: #0c0c28;
}

.css-yn7tg6
  .css-nchosy
  .css-s5hy0i-MuiGrid-root
  .sticky
  .card-holder::-webkit-scrollbar-track-piece {
  background-color: #414166;
}

.css-yn7tg6
  .css-nchosy
  .css-s5hy0i-MuiGrid-root
  .sticky
  .card-holder::-webkit-scrollbar-thumb:vertical {
  background-color: #dddddd42;
}

/* Light Mode */
/* login Css*/
main .css-5mrptg {
  background-color: #f2f2ff;
}

.css-5mrptg [data-amplify-authenticator][data-variation="modal"] {
  background-color: #f2f2ff;
}

.css-5mrptg [data-amplify-authenticator] [data-amplify-router] {
  background-color: #ffffff;
  border: 0;
  border-radius: 20px;
  box-shadow: 0 0 35px #0000000f;
}

.css-5mrptg [data-amplify-authenticator] [data-amplify-form] .amplify-label {
  color: #747397;
}

.css-5mrptg [data-amplify-authenticator] [data-amplify-form] .amplify-input {
  border-color: #d4d4f1;
  padding: 15px 20px;
  border-radius: 10px;
  color: #747397;
}

.css-5mrptg
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-input:focus {
  box-shadow: unset;
}

.css-5mrptg .amplify-input::placeholder {
  color: #babadc;
}

[data-amplify-authenticator]
  [data-amplify-form]
  fieldset.amplify-flex
  .amplify-input[name="password"],
[data-amplify-authenticator]
  [data-amplify-form]
  fieldset.amplify-flex
  .amplify-input[name="confirm_password"] {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

[data-amplify-authenticator]
  [data-amplify-form]
  fieldset.amplify-flex
  .amplify-flex
  .amplify-field-group__outer-start
  + .amplify-field-group__field-wrapper
  > input.amplify-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.custom_container:has(form[data-amplify-authenticator-signup]) {
  max-width: 900px;
}

.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container] {
  width: 100%;
}

.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex {
  flex-wrap: wrap !important;
  flex-direction: row !important;
  align-items: end !important;
}

.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > .amplify-flex,
.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > [data-amplify-sign-up-errors] {
  flex: 0 0 100%;
  max-width: 100%;
}

.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > .amplify-passwordfield,
.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > .amplify-phonenumberfield,
.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > .amplify-flex:last-child {
  flex: 0 0 50%;
  max-width: calc(50% - 8px);
}

.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > .amplify-passwordfield
  + [data-amplify-sign-up-errors] {
  flex: auto;
  max-width: none;
  position: relative;
  margin-left: -51%;
}

.custom_container:has(form[data-amplify-authenticator-signup])
  > .MuiBox-root
  > .MuiPaper-root
  > [data-amplify-authenticator]
  > [data-amplify-container]
  form[data-amplify-authenticator-signup]
  > fieldset.amplify-flex
  > .amplify-flex
  > .amplify-passwordfield
  + [data-amplify-sign-up-errors]
  > p.amplify-text {
  position: absolute;
  width: 100%;
}

[data-amplify-authenticator]
  [data-amplify-form]
  fieldset.amplify-flex
  [data-amplify-sign-up-errors]
  .amplify-text--error {
  line-height: 1.25;
  color: #ff0000;
  font-size: 12px;
}

[data-amplify-authenticator]
  [data-amplify-form]
  fieldset.amplify-flex
  select.amplify-select {
  border-color: #1e1e35;
  color: #1e1e35;
  line-height: 2;
  border-radius: 10px 0px 0px 10px;
}

.css-5mrptg
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-field-group__outer-end
  .amplify-field-group__control {
  border-color: #d4d4f1;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 0;
}

.css-5mrptg .amplify-icon > svg path {
  fill: #747397;
}

.css-5mrptg [data-amplify-authenticator] [data-amplify-footer] button,
.css-5mrptg
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-button--link {
  color: #908fc4;
  font-size: 14px;
}

.css-5mrptg .amplify-field-group__outer-end .amplify-field-group__control:hover,
.css-5mrptg [data-amplify-authenticator] [data-amplify-footer] button:hover,
.css-5mrptg
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-button--link:hover {
  background-color: transparent;
}

.css-5mrptg
  [data-amplify-authenticator]
  [data-amplify-form]
  .amplify-heading--3 {
  font-weight: 600;
}

/* Cashflow */
.MuiBox-root.css-y0u0hj:has(.css-ykcw1u) {
  background-color: #0c0c28;
}

.css-nchosy .css-s5hy0i-MuiGrid-root .sticky {
  position: static !important;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 7px #0000000d;
  padding-bottom: 25px;
  height: 80vh !important;
  width: 100%;
}

.css-5mrptg .css-nchosy .css-s5hy0i-MuiGrid-root .sticky {
  background-color: #ffffff;
}

.css-nchosy .css-s5hy0i-MuiGrid-root .sticky .card-holder {
  overflow-y: auto;
  padding-inline: 30px;
}

.css-nchosy
  .css-s5hy0i-MuiGrid-root
  .sticky
  .card-holder::-webkit-scrollbar-track-piece {
  opacity: 0.2;
  transition: 0.3s ease-in-out;
}

.css-5mrptg
  .css-nchosy
  .css-s5hy0i-MuiGrid-root
  .sticky
  .card-holder::-webkit-scrollbar-thumb:vertical {
  background-color: #b4b4b4;
}

.css-s5hy0i-MuiGrid-root .MuiBox-root > .flex {
  justify-content: normal;
  align-items: center;
  padding-bottom: 15px;
  gap: 30px;
  padding-inline: 4px;
}

.css-s5hy0i-MuiGrid-root .sticky .css-1yjvs5a {
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
}

.css-nchosy
  .css-s5hy0i-MuiGrid-root
  .sticky
  .MuiBox-root.css-1yjvs5a:not(.css-1yjvs5a > .css-1yjvs5a):last-child {
  margin-top: 12px;
  margin-bottom: 30px;
}

.css-nchosy .css-s5hy0i-MuiGrid-root .sticky .css-1d7dz9z-MuiTypography-root {
  padding-top: 24px !important;
  font-weight: 600 !important;
  width: 95%;
  font-size: 24px;
  border-bottom: 1px solid #252542;
  padding-bottom: 15px;
  margin-inline: auto;
  text-align: left;
  padding-left: 15px;
}

/* .css-5mrptg .MuiCard-root {
  background-color: #f2f2ff;
} */

#root .css-ovi9gw .pro-sidebar-inner {
  background-color: #ffffff !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper .pro-icon > svg {
  width: 40px;
  height: 40px;
}

.css-5mrptg .cashflow-right {
  background-color: #fff;
}

.sidebar.css-nvvpnw {
  border-color: #dddddd38;
}

.sidebar.css-ovi9gw {
  border-color: #00000014;
}

.cashflow-right {
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 0 7px #0000000d;
}

main .css-s5hy0i-MuiGrid-root .css-xyfy1j {
  margin: auto !important;
  width: calc(100% - 30px);
}

main .css-s5hy0i-MuiGrid-root .css-9ej16b-MuiSlider-root,
main .css-s5hy0i-MuiGrid-root .css-3n0bl2-MuiSlider-root {
  width: 100%;
}

main .css-s5hy0i-MuiGrid-root .css-17m5sax-MuiTypography-root {
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  padding-left: 15px;
  border-bottom: 1px solid #252542;
  padding-bottom: 15px;
}

main .css-5mrptg .css-s5hy0i-MuiGrid-root .css-17m5sax-MuiTypography-root,
.css-5mrptg
  .css-nchosy
  .css-s5hy0i-MuiGrid-root
  .sticky
  .css-1d7dz9z-MuiTypography-root {
  border-color: #ededed;
}

main .css-s5hy0i-MuiGrid-root {
  padding-block: 10px;
  gap: 2%;
}

.css-hyum1k-MuiToolbar-root:empty {
  display: none;
}

.css-s5hy0i-MuiGrid-root .css-o4bg9w-MuiGrid-root {
  margin-left: 10px;
}
/* 
.btn_styleOne,
.btn_styleTwo:hover {
  background-color: #5c59fd !important;
  border-radius: 10px !important;
  border: 1px solid #5c59fd !important;
  transition: 0.3s ease-in-out;
  color: #fff;
}

.btn_styleOne:hover,
.btn_styleTwo {
  background-color: transparent !important;
  border-radius: 10px !important;
  border: 1px solid #5c59fd !important;
  transition: 0.3s ease-in-out;
  color: #5c59fd;
} */

.cashflow-right .css-17w9904-MuiTypography-root {
  padding-inline: 15px;
}

.custom_progressBar {
  position: relative;
  overflow: hidden;
}

.custom_progressBar > label {
  position: absolute;
  top: calc(50% - 1px);
  left: 10px;
  z-index: 1;
  font-size: 12px;
}

.btn_styleOne.active,
.btn_styleTwo.active {
  background-color: #4cceac !important;
  border-radius: 10px !important;
  border: 1px solid #4cceac !important;
  color: #fff !important;
}
.btn_styleTwo {
  background-color: transparent !important;
  border-radius: 10px !important;
  border: 1px solid #4cceac !important;
  transition: 0.3s ease-in-out;
  color: #4cceac;
}
.btn_styleOne {
  background-color: transparent !important;
  border-radius: 10px !important;
  border: 1px solid #4cceac !important;
  transition: 0.3s ease-in-out;
  color: #4cceac;
}

.btn_styleThree {
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  padding: 8px 24px !important;
  /* color: #5bc0be !important;
  background-color: #345060 !important; */
  border-radius: 50px !important;
  box-shadow: none !important;
}

.btn_styleThree.active {
  color: #ffffff !important;
  background-color: #4cceac !important;
}

.btn_styleThree.active > img {
  filter: brightness(100);
}

main .MuiBox-root .MuiPaper-root {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding-top: 28px;
  padding-bottom: 16px;
}

.css-zwiamn {
  margin-inline: 0;
}

/* .css-yn7tg6,
main {
  padding-inline: 40px;
} */

.table_responsive {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, 1fr);
}

.card_verticalHeading {
  position: relative;
}

.vertical_heading {
  transform: rotate(-90deg);
  position: absolute;
  top: 40%;
  left: -15px;
}

table.loan_table tr th:first-child,
table.loan_table tr td:first-child {
  position: sticky;
  left: 0px;
  background-color: inherit;
  width: 100px;
  max-width: 100px;
  min-width: 100px;
}

table.loan_table tr.sticky_td td:first-child {
  text-align: left !important;
  width: 100px !important;
  max-width: 100px !important;  
  min-width: 100px !important;
  border-right: 0px !important;
}

table.loan_table tr.sticky_td td:last-child {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  border-left: 0px !important;
}

table.loan_table tr th:first-child,
table.loan_table tr:nth-child(2) td:first-child,
table.loan_table tr:nth-child(3) td:first-child,
table.loan_table tr:nth-child(4) td:first-child,
table.loan_table tr:nth-child(6) td:first-child,
table.loan_table tr:nth-child(8) td:first-child,
table.loan_table tr:nth-child(9) td:first-child,
table.loan_table tr:nth-child(10) td:first-child,
table.loan_table tr:nth-child(11) td:first-child,
table.loan_table tr:nth-child(12) td:first-child,
table.loan_table tr:nth-child(13) td:first-child,
table.loan_table tr:nth-child(14) td:first-child{
  background-color: #44bfa5 !important;
  z-index: 9;
}

.multi_stepForm input {
  height: 40px !important;
  padding: 10px 14px !important;
}

.profile_box {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgb(91 192 190 / 10%);
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
}

.profile_box > div:first-child {
  flex: 38px;
  max-width: 38px;
}

.profile_box > div:last-child {
  flex: calc(100% - 38px);
  max-width: calc(100% - 38px);
  padding-left: 10px;
}

ul.profileBox_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

ul.profileBox_list > li {
  flex: 0 0 100%;
  max-width: 150px;
}
ul.profileBox_summarylist {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
ul.profileBox_summarylist > li {
  flex: 0 0 100%;
  max-width: 220px;
}

.investmentScore_box > img {
  height: 345px;
  width: 100%;
  object-fit: contain;
  object-position: top;
}

.investmentScore_imgBox {
  position: relative;
  height: 350px;
  overflow: hidden;
}

.investmentScore_imgBox > img {
  object-fit: contain;
  object-position: center;
}

.investmentScore_imgBox > span {
  position: absolute;
  bottom: 25px;
  left: 49%;
  transform: translateX(-50%);
  font-size: 30px;
  font-weight: 700;
  color: #f57011;
}

.propertyBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
}

.propertyBox > .property_img {
  flex: 0 0 100%;
  max-width: 160px;
}

.propertyBox > .property_content {
  flex: 0 0 100%;
  max-width: calc(100% - 160px);
  padding-left: 10px;
}

.propertyBox > .property_content > button > span.MuiButton-icon > svg {
  width: 12px;
  height: 12px;
  display: block;
}

.propertyBox > .property_content > button {
  line-height: 1;
  padding: 8px 20px;
}

ul.list_one,
ul.list_two,
ul.list_three {
  display: flex;
  justify-content: space-between;
}

ul.list_two {
  padding: 118px 30px 110px;
}

ul.list_three {
  padding: 0px 35px 0px 50px;
}

ul.list_one > li,
ul.list_two > li,
ul.list_three > li {
  flex: 0 0 100%;
  max-width: 25%;
  text-align: center;
  position: relative;
}

ul.list_one > li {
  max-width: 33.33%;
  text-align: left;
}

ul.list_two > li {
  padding: 100px 0px 60px;
}

ul.list_three > li {
  padding: 0px 0px 50px;
}

.investmentStrategy_box > .investmentStrategy_boxInner {
  position: relative;
  width: 990px;
  /* height: 585px; */
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.investmentStrategy_box > .investmentStrategy_boxInner > .main_line {
  position: absolute;
  width: 715px;
  height: 650px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.investmentStrategy_box > .investmentStrategy_boxInner > .main_line > img {
  width: 100%;
  height: 100%;
}

ul.list_two > li > img,
ul.list_three > li > img {
  position: absolute;
}

ul.list_two > li:first-child > img,
ul.list_three > li:last-child > img {
  width: auto;
  height: 165px;
  top: 0;
  left: 50%;
  transform: translate(-80%, -30%);
  z-index: 0;
}

ul.list_three > li:last-child > img {
  transform: translate(20%, -90%);
}

ul.list_two > li:nth-child(2) > img,
ul.list_two > li:nth-child(3) > img,
ul.list_three > li:nth-child(3) > img {
  width: 100%;
  height: 111px;
  top: 0;
  left: 50%;
  transform: translate(-75%, -10%);
  z-index: 0;
  object-fit: contain;
}

ul.list_three > li:nth-child(3) > img {
  height: 78px;
  transform: translate(-28%, -99%);
}

.finish_line > img {
  background-color: #fff;
  border-top: 7px solid #000000;
  padding: 0px;
  border-radius: 5px;
  margin: 7px auto 0px 24%;
}

.heroSection {
  position: relative;
  overflow: hidden;
  padding: 190px 0px 220px;
}

.heroSection .heroimg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 0;
}

ul.services_list,
ul.twoColumn_list {
  display: flex;
  flex-wrap: wrap;
}

ul.list_two {
  padding: 118px 30px 110px;
}

ul.list_three {
  padding: 0px 35px 0px 50px;
}

ul.list_one > li,
ul.list_two > li,
ul.list_three > li,
ul.twoColumn_list > li {
  flex: 0 0 100%;
  max-width: 25%;
  text-align: center;
  position: relative;
}

ul.twoColumn_list > li {
  max-width: 50%;
}

ul.list_one > li {
  max-width: 33.33%;
  text-align: left;
}

ul.list_two > li {
  padding: 0px;
}

ul.list_three > li {
  padding: 0px 0px 50px;
}

.investmentStrategy_box > .investmentStrategy_boxInner {
  position: relative;
  width: 990px;
  /* height: 585px; */
  margin: 0 auto;
  overflow-x: auto;
  overflow-y: hidden;
}

.investmentStrategy_box > .investmentStrategy_boxInner > .main_line {
  position: absolute;
  width: 715px;
  height: 650px;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
}

.investmentStrategy_box > .investmentStrategy_boxInner > .main_line > img {
  width: 100%;
  height: 100%;
}

ul.list_two > li > img,
ul.list_three > li > img {
  position: absolute;
}

ul.list_two > li:first-child > img,
ul.list_three > li:last-child > img {
  width: auto;
  height: 165px;
  top: 0;
  left: 50%;
  transform: translate(-80%, -30%);
  z-index: 0;
}

ul.list_three > li:last-child > img {
  transform: translate(20%, -90%);
}

ul.list_two > li:nth-child(2) > img,
ul.list_two > li:nth-child(3) > img,
ul.list_three > li:nth-child(3) > img {
  width: 100%;
  height: 111px;
  top: 0;
  left: 50%;
  transform: translate(-75%, -10%);
  z-index: 0;
  object-fit: contain;
}

ul.list_three > li:nth-child(3) > img {
  height: 78px;
  transform: translate(-28%, -99%);
}

.finish_line > img {
  background-color: #fff;
  border-top: 7px solid #000000;
  padding: 0px;
  border-radius: 5px;
  margin: 7px auto 0px 24%;
}

/*** 14-06-2024 Investment Strategy Box ***/

ul.list_three.hidden {
  display: none !important;
}

ul.list_one {
  background-size: 740px;
  background-repeat: no-repeat;
  background-position: center bottom;
  padding: 0px 0px 240px;
}

ul.list_two {
  position: relative;
  padding: 0px 10px 0px;
  margin: 0px;
  z-index: 1;
}

ul.list_two > li:first-child > img {
  width: auto;
  height: 200px;
  top: 0;
  left: 50%;
  transform: translate(-85%, -40%);
  z-index: 0;
}

ul.list_two > li:first-child > img.active_img {
  transform: translate(-85%, -50%);
  height: 281px;
  width: 15px;
}

ul.list_two > li:nth-child(2) > img,
ul.list_two > li:nth-child(3) > img,
ul.list_three > li:nth-child(3) > img {
  width: 100%;
  height: 130px;
  top: 0;
  left: 50%;
  transform: translate(-75%, -22%);
  z-index: 0;
  object-fit: contain;
}

/* ul.list_two > li > .active_img {
  display: none;
} */

.heroSection {
  position: relative;
  overflow: hidden;
  padding: 190px 0px 220px;
}

.heroSection .heroimg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 0;
}

ul.services_list {
  display: flex;
  flex-wrap: wrap;
}

ul.services_list > li {
  flex: 0 0 100%;
  max-width: 33.33%;
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.ctaSection {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.cta_inner {
  max-width: 1860px;
  margin: 0 auto;
}

.faq_box {
  padding: 100px 120px;
}

.faq_box > .MuiAccordion-root > .MuiAccordionSummary-root {
  min-height: auto !important;
}

.faq_box
  > .MuiAccordion-root
  > .MuiAccordionSummary-root
  > .MuiAccordionSummary-content {
  margin: 0px !important;
}

.table_styleOne > table > thead > tr > th,
.table_styleOne > table > tbody > tr > td {
  font-size: 14px;
  font-weight: 600;
  border-color: rgb(91 192 190 / 15%) !important;
}

.table_styleOne > table > thead > tr > th,
.table_styleTwo > table > thead > tr > th {
  padding: 10px 16px;
  border-top: 1px solid rgb(91 192 190 / 15%) !important;
}

.table_styleOne > table > tbody > tr:nth-child(odd) {
  background-color: rgb(91 192 190 / 5%) !important;
}

.table_styleTwo > table > thead > tr > th {
  border-top: 1px solid rgb(91 192 190 / 15%) !important;
}

.table_styleTwo > table > tbody > tr {
  background-color: rgb(91 192 190 / 5%);
}

/* .table_styleTwo > table > thead > tr:hover,
.table_styleTwo > table > tbody > tr:hover {
  background-color: #dadada !important;
} 

.table_styleTwo > table > thead > tr:hover > td,
.table_styleTwo > table > tbody > tr:hover > td {
  color: rgba(0, 0, 0, 0.87) !important;
} */

/*
.pagination,
.page-numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.pagination {
  margin: 20px 20px;
}

.btn-page {
  width: 28px;
  height: 28px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid rgb(91 192 190 / 20%);
  cursor: pointer;
}

.btn-nav > svg {
  width: 20px;
  height: 20px;
}

.btn-selected,
.btn-page:hover {
  background-color: #5bc0be;
  color: #fff;
}

.btn-nav:hover {
  color: #5bc0be !important;
}

/****************************************/
/************ NEW FLOWCHART ************/
/**************************************/

.firstStep_line {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 735px;
  opacity: 0.2;
}

ul.list_one {
  padding: 0px 0px 230px;
}

.firstStep_firstStep_box {
  width: 515px;
  position: relative;
  padding-top: 164px;
}

.firstStep_firstStep_box > .firstStep_firstStep_line,
.firstStep_secondStep_box > .firstStep_secondStep_line,
.firstStep_secondStep_secondStep_box > .firstStep_secondStep_line,
.secondStep_firstStep_box .secondStep_firstStep_line,
.secondStep_secondStep_box .secondStep_secondStep_line,
.threeStep_firstStep_box .threeStep_firstStep_line,
.threeStep_secondStep_box .threeStep_secondStep_line,
.threeStep_threeStep_box .threeStep_threeStep_line,
.threeStep_fourStep_box .threeStep_fourStep_line,
.fourStep_firstStep_box .fourStep_firstStep_line,
.fourStep_secondStep_box .fourStep_secondStep_line,
.fourStep_thirdStep_box .fourStep_thirdStep_line {
  position: absolute;
  top: -274px;
  left: 122px;
  width: 340px;
  opacity: 0.9;
  z-index: 0;
}

.firstStep_firstStep_box > .twoColumn_list > li:nth-child(2) {
  text-align: right;
}

.firstStep_secondStep_secondStep_box,
.firstStep_secondStep_box {
  width: 660px;
  position: relative;
}

.firstStep_secondStep_box > .firstStep_secondStep_line,
.firstStep_secondStep_secondStep_box > .firstStep_secondStep_line {
  width: 500px;
}

.firstStep_secondStep_box > .listOne,
.firstStep_secondStep_secondStep_box > .listOne {
  padding: 165px 0px 190px;
}

.firstStep_secondStep_box > .listOne > li:first-child,
.firstStep_secondStep_box > .listOne > li:nth-child(2),
.firstStep_secondStep_secondStep_box > .listOne > li:first-child,
.firstStep_secondStep_secondStep_box > .listOne > li:nth-child(2) {
  padding-right: 65px;
}

.firstStep_secondStep_box > .listTwo,
.firstStep_secondStep_secondStep_box > .listTwo {
  width: 550px;
  margin: 0 -15px 0 auto;
}

.firstStep_secondStep_box > .listTwo > li:first-child,
.firstStep_secondStep_box > .listTwo > li:nth-child(2),
.firstStep_secondStep_secondStep_box > .listTwo > li:first-child,
.firstStep_secondStep_secondStep_box > .listTwo > li:nth-child(2) {
  text-align: right;
}

.secondStep_firstStep_box,
.secondStep_secondStep_box {
  position: relative;
  width: 285px;
  left: 125px;
}

.secondStep_firstStep_box .secondStep_firstStep_line,
.secondStep_secondStep_box .secondStep_secondStep_line {
  width: 465px;
  left: 0px;
}

.secondStep_firstStep_box > ul.listOne,
.secondStep_secondStep_box > ul.listOne {
  padding-top: 150px;
  text-align: right;
  width: 520px;
}

.secondStep_firstStep_box > ul.listOne > li:first-child,
.secondStep_secondStep_box > ul.listOne > li:first-child {
  padding-right: 50px;
}

.threeStep_firstStep_box,
.threeStep_secondStep_box,
.threeStep_threeStep_box,
.threeStep_fourStep_box,
.fourStep_firstStep_box,
.fourStep_secondStep_box,
.fourStep_thirdStep_box {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.threeStep_firstStep_box .threeStep_firstStep_line,
.threeStep_secondStep_box .threeStep_secondStep_line {
  width: 635px;
  left: 127px;
}

.threeStep_threeStep_box .threeStep_threeStep_line,
.threeStep_fourStep_box .threeStep_fourStep_line {
  width: 780px;
  left: 127px;
}

.fourStep_firstStep_box .fourStep_firstStep_line,
.fourStep_secondStep_box .fourStep_secondStep_line,
.fourStep_thirdStep_box .fourStep_thirdStep_line {
  width: 740px;
  left: 127px;
}

.threeStep_firstStep_box > .listOne,
.threeStep_secondStep_box > .listOne,
.threeStep_threeStep_box > .listOne,
.threeStep_threeStep_box > .listTwo,
.threeStep_fourStep_box > .listOne,
.threeStep_fourStep_box > .listTwo,
.fourStep_firstStep_box > .listOne,
.fourStep_secondStep_box > .listOne,
.fourStep_thirdStep_box > .listOne {
  display: flex;
  justify-content: space-between;
  padding: 150px 50px 75px;
}

.threeStep_secondStep_box .listTwo {
  text-align: center;
  margin-right: 232px;
}

.fourStep_firstStep_box > .listOne,
.fourStep_secondStep_box > .listOne,
.fourStep_thirdStep_box > .listOne {
  width: 710px;
  margin-left: auto;
  margin-right: 15px;
  padding-bottom: 52px;
}

.fourStep_secondStep_box > .listOne,
.fourStep_thirdStep_box > .listOne {
  padding-bottom: 59px;
}

.threeStep_threeStep_box > .listTwo {
  padding: 106px 0px 0px;
  width: 358px;
  margin-left: auto;
  margin-right: 52px;
}

.threeStep_fourStep_box > .listTwo {
  padding: 105px 0px 0px;
  width: 352px;
  margin-left: auto;
  margin-right: 58px;
}

.fourStep_firstStep_box > .listTwo {
  width: 310px;
  margin: 0px auto 0px 234px;
  text-align: center;
}

.fourStep_secondStep_box > .listTwo {
  text-align: center;
  margin-left: 240px;
}

.fourStep_thirdStep_box > .listTwo {
  text-align: right;
  margin-right: 85px;
}

.threeStep_secondStep_box > .listOne > li:nth-child(2),
.threeStep_threeStep_box > .listOne > li:nth-child(2),
.threeStep_fourStep_box > .listOne > li:nth-child(2) {
  padding-right: 25px;
}

.fourStep_firstStep_box > .listOne > li:first-child,
.fourStep_secondStep_box > .listOne > li:first-child,
.fourStep_thirdStep_box > .listOne > li:first-child {
  padding-left: 15px;
}

.tab_styleOne {
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: rgb(79 162 165 / 60%);
  border: 3px solid #4fa2a5;
  border-radius: 20px;
  padding: 20px;
}

.tab_styleOne > .MuiTabs-vertical {
  min-width: 290px;
}

.tab_styleOne > .MuiTabs-vertical .MuiButtonBase-root {
  background-color: transparent;
  min-height: auto;
  padding: 8px;
}

.tab_styleOne > div[role="tabpanel"] > .MuiBox-root {
  padding: 0px;
}

.tab_styleOne > .MuiTabs-vertical > .MuiTabs-scroller > .MuiTabs-flexContainer {
  gap: 20px;
}

.tab_styleOne > .MuiTabs-vertical > .MuiTabs-scroller > span.MuiTabs-indicator {
  width: 0px !important;
}

.tab_styleOne > .MuiTabs-vertical .MuiButtonBase-root > img {
  width: 38px;
  height: 38px;
  display: block;
  padding: 8px;
  background-color: #5bc0be;
  border-radius: 10px;
  object-fit: contain;
  object-position: center;
  margin-right: 10px;
}

label.custom_switch {
  margin-right: 0px !important;
}

label.custom_switch > .MuiFormControlLabel-label {
  display: none;
}

.profileSection,
.add_investorCard {
  position: relative;
  overflow: hidden;
  z-index: 0;
  box-shadow: 0px 12px 12px -6px rgb(0 0 0 / 20%);
}

.profileSection::before,
.profileSection::after,
.add_investorCard::before,
.add_investorCard::after,
main [data-amplify-authenticator] [data-amplify-router]::before,
main [data-amplify-authenticator] [data-amplify-router]::after {
  content: "";
  width: 550px;
  height: 550px;
  display: block;
  border: 120px solid rgb(0 189 162 / 10%);
  border-radius: 50%;
  position: absolute;
  top: -180px;
  right: -180px;
  z-index: 0;
}


main [data-variation="modal"]:has(form[data-amplify-authenticator-signin]) [data-amplify-router]::before,
main [data-variation="modal"]:has(form[data-amplify-authenticator-signin]) [data-amplify-router]::after,
main [data-variation="modal"]:has(form[data-amplify-authenticator-resetpassword]) [data-amplify-router]::before,
main [data-variation="modal"]:has(form[data-amplify-authenticator-resetpassword]) [data-amplify-router]::after,
main .custom_container:has(form[data-amplify-authenticator-confirmsignin]) [data-amplify-router]::before,
main .custom_container:has(form[data-amplify-authenticator-confirmsignin]) [data-amplify-router]::after {
  width: 360px;
  height: 360px;
  border: 100px solid rgb(0 189 162 / 10%);
}

main [data-amplify-authenticator] [data-amplify-router] form[data-amplify-form] {
  position: relative;
  z-index: 1;
}

.profileSection::after,
.add_investorCard::after,
main [data-amplify-authenticator] [data-amplify-router]::after {
  width: 450px;
  height: 450px;
  top: auto;
  right: auto;
  border: 120px solid rgb(0 189 162 / 10%);
  bottom: -150px;
  left: -150px;
}

.add_investorCard::before,
.add_investorCard::after {
  background: rgb(0 189 162 / 6%);
  border-radius: 10px;
  transform: rotate(30deg);
  border: 80px solid rgb(0 189 162 / 6%);
  border-radius: 10px;
  position: absolute;
  top: -50%;
  right: -30%;
}

.add_investorCard::after {
  top: 25%;
  right: auto;
  left: -20%;
}

.profileSection > .profile_inner,
.add_investorCard > .investorCard_inner {
  position: relative;
  z-index: 1;
}

.profileSection > .profile_inner .profile_img {
  width: 200px !important;
  height: 200px !important;
  object-fit: contain;
  object-position: center;
  background-color: #f2f1ff;
  border: 3px solid #ffffff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px -3px rgb(0 0 0 / 20%);
}

.add_investorCard > .investorCard_inner form > ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -10px 20px;
}

.add_investorCard > .investorCard_inner form > ul > li {
  flex: 0 0 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.add_investorCard > .investorCard_inner form > ul > li:nth-child(3) {
  max-width: 100%;
}

.add_investorCard > .investorCard_inner form input,
.add_investorCard > .investorCard_inner form select {
  background: #ffffff;
  border: 1px solid rgb(0 189 162 / 15%);
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 5px;
  font-size: 16px;
  box-shadow: 0px 12px 12px -6px rgb(0 0 0 / 10%);
  outline: none !important;
  min-height: 48px;
}

.add_investorCard > .investorCard_inner form input:focus,
.add_investorCard > .investorCard_inner form select:focus {
  border: 1px solid #00bda2;
}

.strip_container {
  padding: 10px;
  background: #ffffff;
  border: 1px solid rgb(0 189 162 / 15%);
  padding: 14px 14px;
  border-radius: 6px;
  margin-top: 5px;
  font-size: 16px;
  min-height: 48px;
  box-shadow: 0px 12px 12px -6px rgb(0 0 0 / 10%);
}













@media (min-width: 1920px) {
  /* .landing_page .MuiContainer-root {
    max-width: 1890px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 0 auto !important;
  } */
}

@media (max-width: 1279.98px) {
  .cashflow-left {
    margin-bottom: 40px !important;
  }

  .propertyBox > .property_img {
    max-width: 140px;
  }

  .propertyBox > .property_content {
    max-width: calc(100% - 140px);
  }
}

@media (max-width: 599.98px) {
  .propertyBox > .property_img,
  .propertyBox > .property_content {
    max-width: 100%;
  }

  .propertyBox > .property_content {
    padding: 10px 0px 0px;
  }
}

.gradient-text {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  -webkit-background-clip: text;
}

.gradient-border {
  position: relative;
  display: inline-block;
  font-size: 24px; /* Adjust as needed */
  font-weight: bold; /* Adjust as needed */

  -webkit-background-clip: text;

  border: 2px solid transparent; /* Transparent to show the gradient border */
  border-radius: 5px; /* Apply border radius */
  border-image: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-image-slice: 1;
  padding: 10px; /* Add padding to make sure text doesn't touch the border */
}
.gradient-border-ba-dash {
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  /* text-align: center; */
  border: 0px solid transparent;
  border-radius: 15px;
  padding: 8px;
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  /* border-image-slice: 1; */
  /* padding: 16px 16px; */
  background-size: 150% 150%;
  animation: gradient 1s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.gradient-border-ba-dash > .MuiBox-root {
  background-color: rgb(226 236 235);
  border-radius: 8px;
}

.notachivegradient-text {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(60deg, #f79533, #e43614, #ef4e7b);
  -webkit-background-clip: text;
}

.chnagebackgound {
  background-color: #fdcccb !important;
}
/* .css-rt6ue7-MuiButtonBase-root-MuiButton-root:hover{
  background: linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%) !important;
  color: white !important;
} */

.css-vmv3p0-MuiButtonBase-root-MuiButton-root:hover {
  background: linear-gradient(
    136deg,
    #3100ff 0%,
    #633dbf 50%,
    #b500ff 100%
  ) !important;
  color: white !important;
}

.css-vmv3p0-MuiButtonBase-root-MuiButton-root {
  background: linear-gradient(
    136deg,
    #3100ff 0%,
    #633dbf 50%,
    #b500ff 100%
  ) !important;
  color: white !important;
}

.search_agentSection {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 72px);
}

.search_agentSection .heroimg {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  z-index: 0;
}

.custom_searchOne
  > .MuiAutocomplete-root
  > .MuiFormControl-root
  > .MuiInputBase-root {
  padding-right: 10px !important;
}

.custom_searchOne
  > .MuiAutocomplete-root
  > .MuiFormControl-root
  > .MuiInputBase-root
  > input {
  color: #000 !important;
  z-index: 1;
}

.custom_searchOne
  > .MuiAutocomplete-root
  fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #5bc0be;
  background-color: #ffffff;
}

.custom_searchOne
  > .MuiAutocomplete-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #5bc0be !important;
}

.custom_searchOne
  > .MuiAutocomplete-root
  > .MuiFormControl-root
  > .MuiInputBase-root
  > .MuiInputAdornment-root {
  z-index: 1 !important;
}

.custom_searchOne
  > .MuiAutocomplete-root
  > .MuiFormControl-root
  > .MuiInputBase-root
  > .MuiInputAdornment-root
  > svg {
  color: #000000 !important;
}

.dashBoard_search {
  margin-left: auto !important;
}
.pagination {
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;

}

/*********30-10-24********/

.Mui-completed .MuiStepConnector-line.MuiStepConnector-lineVertical {
    background-image: linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%);
    height: 100%;
    width: 2px;
    margin-left: 10px;
    display:block !important;
}
.Mui-active .MuiStepConnector-line.MuiStepConnector-lineVertical {
    background-image: linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%);
    height: 100%;
    width: 2px;
    margin-left: 10px;
    display:block !important;
}
.Mui-disabled .MuiStepConnector-line.MuiStepConnector-lineVertical{
  background-image: #212121;
  height: 100%;
  width: 2px;
  margin-left: 10px;
}
.step_content {
    margin-left: 22px !important;
    padding-left: 20px !important;
    padding-right: 8px !important;
    border-left: 2px solid #212121 !important;
}
.MuiTabs-scroller button.Mui-selected {
    background-image: linear-gradient(136deg, #3100ff 0%, #633dbf 50%, #b500ff 100%);
    opacity: 1;
    color: #fff;
}

/*********08-11-24********/
table.loan_table input{
  text-align: center;
  width: 55px;
  /* border-radius: 10px;
  border: 1px solid rgb(68 191 165); */
}
table.loan_table, th, td{
  border: 1px solid #e0e0e0;
  text-align: center !important;
}
table.loan_table .table-two-items{
  display: flex;
  gap: 10px;
  justify-content: center;
}
table.loan_table .table-two-items span{
  font-size: 10px;
}
table.loan_table .table-two-items span b{
  font-size: 14px;
  font-weight: 600;
}
.table-box-card{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.table-box-card h3, .table-box-card h6{
  text-align: left;
}
.table-box-card h6{
  padding-left:8px;
  margin-left: 12px;
}


.UserLogo img .visiVAlign {
    vertical-align: middle;
    display: none;
}


/*******************20-01-2025***********************/

.portfolio-page .divider-box {
  display: flex;
  gap: 20px;
}
.portfolio-page .divider-box svg path{
  fill: #07b39b;
}
.map-banner{
  margin-bottom:50px;
  position:relative;
  border-radius: 15px;
}
.map-banner:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 50%);
}
.map-banner iframe{
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.map-banner .map-content {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  color: #000;
  z-index: 1;
  right: 0;
  width: -moz-fit-content;
  width: fit-content;
  gap: 20px;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
}
.map-banner .map-content svg{
  width: 30px;
  height: 30px;

}
.map-banner .map-content svg path{
  fill: #00bda2;
}
.map-banner .map-content h6{
  margin-top:15px;
}
.map-banner .map-content button{
  width: fit-content;
  float: right;
}
.map-banner .map-content h4, .map-banner .map-content h6{
  color: #000;
}
.portfolio-page .box-1 {
  border-bottom: 5px solid #4cceacc9;
}


.portfolio-page .price-card {
  background: radial-gradient(circle, #0f9471 2%, #4cceaccf 50%, #4cceaccf 100%);
  border-radius: 8px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 20px auto;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 80px 20px;
}
.portfolio-page .price-card .main-price-sec {
  display: flex;
  gap: 20px;
}
.portfolio-page .price-card .main-price-sec .value {
  font-size: 20px;
  font-weight: 600;
}
.portfolio-page .price-card .main-price-sec .label{
  font-size: 25px;
  font-weight: 700;
}
.portfolio-page .sale-card {
  margin-top: 20px;
  /* display: flex;
  flex-direction: column;
  gap: 20px; */
  padding-bottom: 30px;
}
.portfolio-page .price-card:after {
  content: '';
  background-image: url('assets/dollars.png');
  position: absolute;
  left: 29px;
  background-size: contain;
  height: 200px;
  top: -54px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  z-index: -1;
}
.portfolio-page .price-card:before {
  content: '';
  background-image: url('assets/dollars.png');
  position: absolute;
  left: 20px;
  background-size: contain;
  height: 199px;
  bottom: -31px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  transform: translateZ(10px);
  z-index: -1;
}


.portfolio-page .price-card .label {
  display: block;
  font-size: 1.2rem;
  font-weight:medium;
}

.portfolio-page .price-card .value {
  display: block;
  font-size: 1.5rem;
  margin-top: 5px;
}
.portfolio-page .price-card .price-title{
  font-size: 25px;
  text-transform: capitalize;
  font-weight: bold;
  margin-bottom: 20px;
}
.portfolio-page .card_new {
  padding: 10px 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  border-radius: 10px;
  height: 100%;
  border: 2px solid transparent;
  transition: 0.5s;
}
.portfolio-page .card_new:hover{
  border: 2px solid #07b39b;
}
.portfolio-page .card_new p{
  font-size:15px;
}
.portfolio-page .card_new svg {
  width: 55px;
  height: 55px;
  padding: 12px;
  border-radius: 50%;
  background: #4cceac33;
}
.portfolio-page .card_new svg path, .portfolio-page .card_new svg circle{
  fill: #00bda2;
}
@media (max-width:1200px){
  .portfolio-page .divider-box {
    flex-direction: column;
    gap: 15px;
  }
}

@media (max-width:767px){
  .map-banner .map-content {
    flex-direction: column;
    gap: 20px;
}
.map-banner .map-content button{
  width: fit-content;
}
}